<template>
  <div class="main">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>数据概况</span>
        <div style="margin-top: 18px; display: flex; flex-wrap: wrap">
          <div class="">
            <span class="label">注册时间： </span>
            <el-date-picker
              size="small"
              v-model="filterData.created_at"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="width: 400px"
            />
          </div>
        </div>
        <div style="display: flex; flex-wrap: wrap; margin-top: 10px">
          <el-button
            size="small"
            type="primary"
            @click="query()"
            icon="el-icon-search"
          >
            查询
          </el-button>
          <el-button size="small" @click="filterData = {}" icon="el-icon-plus">
            重置
          </el-button>
        </div>
      </div>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-card style="margin-top: 10px">
            <div slot="header" class="clearfix">
              <span>H5端用户注册数据</span>
            </div>
            <div id="chartLeft" style="min-height: 500px; width: 100%"></div>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card style="margin-top: 10px">
            <div slot="header" class="clearfix">
              <span>小程序端用户注册数据</span>
            </div>
            <div id="chartRight" style="min-height: 500px; width: 100%"></div>
          </el-card>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      filterData: {},
    };
  },
  created() {
    this.query();
  },
  methods: {
    urlToName(data) {
      const map = {
        "/": "首页",
        "/woooselect": "WOOO SELECT",
        "/woooseries": "WOOO SELECT",
        "/product/[[...id]]": "WOOO SERIES商品详情页",
        "/account": "我的",
        // 小程序
        "/pages/home/home?type=0": "首页",
        "/pages/home/home?type=1": "WOOO SELECT",
        "/pages/home/home?type=2": "WOOO SERIES",
        "/pages/goodsDetails/goodsDetails?type=0": "WOOO SERIES商品详情页",
        "/pages/goodsDetails/goodsDetails?type=1": "WOOO SELECT商品详情页",
        "/pages/pay/pay": "下单页面",
        "/pages/collection/collection": "我的收藏",
        "/pages/goodsDetails/goodsDetails": "商品详情页",
      };
      return {
        web: (data.web || []).map((i) => {
          return {
            value: i.counts,
            name: `${map[i.register_visit_page] || "其它"}-${
              i.register_click_btn
            }`,
          };
        }),
        xcx: (data.xcx || []).map((i) => {
          return {
            value: i.counts,
            name: `${map[i.register_visit_page] || "其它"}-${
              i.register_click_btn
            }`,
          };
        }),
      };
    },
    getOptions(data) {
      return {
        tooltip: {
          trigger: "item",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          right: 0,
          top: 0,
          bottom: 20,
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["40%", "70%"],
            center: ['30%', '50%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 16,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data,
          },
        ],
      };
    },
    query() {
      this.$http
        .post("/admin/stats/register_visit_page", this.filterData)
        .then((res) => {
          if (res.status === 200) {
            const { web, xcx } = this.urlToName(res.data.data || []);
            console.log(web, xcx);
            let option1 = this.getOptions(web);
            let myChart1 = this.$echarts.init(
              document.getElementById("chartLeft")
            );
            myChart1.clear();
            myChart1.setOption(option1);
            let option2 = this.getOptions(xcx);
            let myChart2 = this.$echarts.init(
              document.getElementById("chartRight")
            );
            myChart2.clear();
            myChart2.setOption(option2);
          }
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped></style>
